import { useEffect, useRef, useState } from 'react'
import { Vector3 } from 'three'
import { useThree } from '@react-three/fiber'
import { Environment, OrbitControls, Stats } from '@react-three/drei'

import MeshWithPivot from './components/core/MeshWithPivot'
import DistanceMeasurement from './components/core/DistanceMeasurements'

import { autoFitMeshToCameraFrustum } from './core/utils'

export default function Experience({ meshes, setMeshes, activeMeshId, setActiveMeshId, measurementsActive }) {
  // const { camera } = useThree()
  const orbitCtrlRef = useRef(null)

  const [measurementRulerPosXVisibility, setMeasurementRulerPosXVisibility] = useState(false)
  const [measurementRulerNegXVisibility, setMeasurementRulerNegXVisibility] = useState(false)
  const [measurementRulerPosZVisibility, setMeasurementRulerPosZVisibility] = useState(false)
  const [measurementRulerNegZVisibility, setMeasurementRulerNegZVisibility] = useState(false)
  const [measurementRulerPosX, setMeasurementRulerPosX] = useState([new Vector3(0, 0, 0), new Vector3(0, 0, 0)])
  const [measurementRulerNegX, setMeasurementRulerNegX] = useState([new Vector3(0, 0, 0), new Vector3(0, 0, 0)])
  const [measurementRulerPosZ, setMeasurementRulerPosZ] = useState([new Vector3(0, 0, 0), new Vector3(0, 0, 0)])
  const [measurementRulerNegZ, setMeasurementRulerNegZ] = useState([new Vector3(0, 0, 0), new Vector3(0, 0, 0)])

  useEffect(() => {
    const handleKeyDown = (event) => {
      // DEL KEY
      if (event.key === 'Delete' && activeMeshId) {
        setMeshes((prevMeshes) => prevMeshes.filter((mesh) => mesh.id !== activeMeshId))
        setActiveMeshId(0)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [activeMeshId])

  // whenever we add/remove a mesh select the last one added
  useEffect(() => {
    if (meshes.length) {
      const lastAddedMeshIndex = meshes.length - 1
      setActiveMeshId(meshes[lastAddedMeshIndex].id)

      // autoFitMeshToCameraFrustum(meshes[lastAddedMeshIndex], camera)
    }
  }, [meshes])

  return (
    <>
      <Environment files="/environment-map/venice_sunset_1k.hdr" background backgroundBlurriness={0.5} />
      <OrbitControls ref={orbitCtrlRef} minPolarAngle={-Math.PI} maxPolarAngle={Math.PI} />
      <ambientLight intensity={0.75} />
      <directionalLight intensity={1.0} position={[10, 4, 8]} castShadow />
      {/* <Stats /> */}
      <gridHelper args={[16, 16]} />

      {meshes.map((mesh) => (
        <MeshWithPivot
          key={mesh.id}
          meshes={meshes}
          mesh={mesh}
          orbitCtrlRef={orbitCtrlRef}
          activeMeshId={activeMeshId}
          setActiveMeshId={setActiveMeshId}
          measurementsActive={measurementsActive}
          setMeasurementRulerPosXVisibility={setMeasurementRulerPosXVisibility}
          setMeasurementRulerNegXVisibility={setMeasurementRulerNegXVisibility}
          setMeasurementRulerPosZVisibility={setMeasurementRulerPosZVisibility}
          setMeasurementRulerNegZVisibility={setMeasurementRulerNegZVisibility}
          setMeasurementRulerPosX={setMeasurementRulerPosX}
          setMeasurementRulerNegX={setMeasurementRulerNegX}
          setMeasurementRulerPosZ={setMeasurementRulerPosZ}
          setMeasurementRulerNegZ={setMeasurementRulerNegZ}
        />
      ))}

      {measurementsActive && measurementRulerPosXVisibility && (
        <DistanceMeasurement
          start={measurementRulerPosX[0]}
          end={measurementRulerPosX[1]}
          description={
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="red">
              <path d="M280-320 120-480l160-160 57 56-64 64h414l-63-64 56-56 160 160-160 160-56-56 63-64H273l63 64-56 56Z" />
            </svg>
          }
        />
      )}
      {measurementsActive && measurementRulerNegXVisibility && (
        <DistanceMeasurement
          start={measurementRulerNegX[0]}
          end={measurementRulerNegX[1]}
          description={
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="red">
              <path d="M280-320 120-480l160-160 57 56-64 64h414l-63-64 56-56 160 160-160 160-56-56 63-64H273l63 64-56 56Z" />
            </svg>
          }
        />
      )}
      {measurementsActive && measurementRulerPosZVisibility && (
        <DistanceMeasurement
          start={measurementRulerPosZ[0]}
          end={measurementRulerPosZ[1]}
          description={
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="red">
              <path d="M280-320 120-480l160-160 57 56-64 64h414l-63-64 56-56 160 160-160 160-56-56 63-64H273l63 64-56 56Z" />
            </svg>
          }
        />
      )}
      {measurementsActive && measurementRulerNegZVisibility && (
        <DistanceMeasurement
          start={measurementRulerNegZ[0]}
          end={measurementRulerNegZ[1]}
          description={
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="red">
              <path d="M280-320 120-480l160-160 57 56-64 64h414l-63-64 56-56 160 160-160 160-56-56 63-64H273l63 64-56 56Z" />
            </svg>
          }
        />
      )}
    </>
  )
}
