import { useEffect, useRef } from 'react'

import { Peer } from 'peerjs'
import toast, { toastConfig } from 'react-simple-toasts'
import 'react-simple-toasts/dist/theme/dark-edge.css'

toastConfig({ theme: 'dark-edge' })

const PeerToPeerStream = ({ setPeerToPeerOnClickHandle }) => {
  const myPeerId = useRef(null)
  const theirPeerId = useRef(null)
  const peer = useRef(new Peer())

  const isCameraMediaSet = useRef(false)
  const didHECHECKFORURLPEERID = useRef(false)
  let userAVStream = useRef(null)
  let userCameraVideo = useRef(null)
  let outgoingStreamCanvas = useRef(null)
  let incomingStreamVideo = useRef(null)
  const cameraStream = useRef(null)

  const getUserMedia = async () => {
    try {
      userAVStream.current = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      setupStreams()
    } catch (error) {
      console.error('Error accessing media devices.', error)
    }
  }

  const setupStreams = () => {
    userCameraVideo.current.srcObject = new MediaStream(userAVStream.current.getVideoTracks())
    userCameraVideo.current.play()

    const ctx = outgoingStreamCanvas.current.getContext('2d')
    const drawFrame = () => {
      if (userCameraVideo.current.paused || userCameraVideo.current.ended) return
      const aspectRatio = userCameraVideo.current.videoHeight / userCameraVideo.current.videoWidth
      const userCameraVideoWidth = 384
      const userCameraVideoHeight = Math.round(userCameraVideoWidth * aspectRatio)

      outgoingStreamCanvas.current.width = userCameraVideoWidth
      outgoingStreamCanvas.current.height = userCameraVideoHeight

      ctx.drawImage(userCameraVideo.current, 0, 0, userCameraVideoWidth, userCameraVideoHeight)

      requestAnimationFrame(drawFrame)
    }
    requestAnimationFrame(drawFrame)

    cameraStream.current = outgoingStreamCanvas.current.captureStream(30)
    cameraStream.current.addTrack(userAVStream.current.getAudioTracks()[0])
  }

  const stopStreaming = () => {
    incomingStreamVideo.current.style.display = 'none'
    userAVStream.current.getTracks().forEach((track) => track.stop())
    isCameraMediaSet.current = false
  }

  const checkURLforPeerId = async () => {
    didHECHECKFORURLPEERID.current = true
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('peerId')) {
      if (!isCameraMediaSet.current) {
        await getUserMedia()
        isCameraMediaSet.current = true
      }
      if (cameraStream.current) {
        theirPeerId.current = urlParams.get('peerId')
        const call = peer.current.call(theirPeerId.current, cameraStream.current)

        console.log('calling from url peer id')
        call.on('stream', (remoteStream) => {
          console.log('getting stream from other initpeer')
          incomingStreamVideo.current.style.display = 'block'
          incomingStreamVideo.current.srcObject = remoteStream
        })
        call.on('close', () => {
          stopStreaming()
        })
        call.on('error', () => {
          stopStreaming()
        })
      } else {
        isCameraMediaSet.current = false
      }
    }
  }

  useEffect(() => {
    if (didHECHECKFORURLPEERID.current) return

    peer.current.on('open', (id) => {
      myPeerId.current = id
    })
    peer.current.on('call', (call) => {
      console.log('got a call from url holder')
      call.answer(cameraStream.current)
      call.on('stream', (remoteStream) => {
        console.log('getting stream from other urlpeer')
        incomingStreamVideo.current.style.display = 'block'
        incomingStreamVideo.current.srcObject = remoteStream
      })
      call.on('close', () => {
        stopStreaming()
      })
      call.on('error', () => {
        stopStreaming()
      })
    })
    peer.current.on('close', () => {
      stopStreaming()
    })
    peer.current.on('error', () => {
      stopStreaming()
    })

    checkURLforPeerId()
  }, [])

  const PeerToPeerOnClickHandle = async () => {
    if (!isCameraMediaSet.current) {
      await getUserMedia()
      isCameraMediaSet.current = true
    }
    if (cameraStream.current) {
      navigator.clipboard
        .writeText(`${window.location.href}?peerId=${myPeerId.current}`)
        .then(() => {
          toast('Your video conference link has been copied to the clipboard.')
        })
        .catch((err) => {
          console.error('Failed to copy peer ID:', err)
        })
    } else {
      isCameraMediaSet.current = false
    }
  }

  useEffect(() => {
    setPeerToPeerOnClickHandle(() => PeerToPeerOnClickHandle)
  }, [])

  return (
    <>
      <video ref={userCameraVideo} style={{ display: 'none' }}></video>
      <canvas ref={outgoingStreamCanvas} style={{ display: 'none' }}></canvas>
      <video
        ref={incomingStreamVideo}
        className="z-999 absolute bottom-6 right-6"
        style={{ display: 'none' }}
        autoPlay
      ></video>
    </>
  )
}

export default PeerToPeerStream
