import { createRef, useRef, useEffect } from 'react'
import { useThree } from '@react-three/fiber'

const sceneRef = createRef()

export function useSceneRef() {
  return sceneRef
}

export function SceneProvider({ children }) {
  const { scene } = useThree()

  useEffect(() => {
    sceneRef.current = scene
  }, [scene])

  return <>{children}</>
}
