import { useEffect, useRef, useState } from 'react'
import { ArrowHelper, Raycaster, Vector3 } from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { Outlines, PivotControls } from '@react-three/drei'

import Mouse from '../../core/Mouse'
import DistanceMeasurement from './DistanceMeasurements'

const mouse = new Mouse()

export default function MeshWithPivot({
  meshes,
  mesh,
  activeMeshId,
  setActiveMeshId,
  orbitCtrlRef,
  measurementsActive,
  setMeasurementRulerPosXVisibility,
  setMeasurementRulerNegXVisibility,
  setMeasurementRulerPosZVisibility,
  setMeasurementRulerNegZVisibility,
  setMeasurementRulerPosX,
  setMeasurementRulerNegX,
  setMeasurementRulerPosZ,
  setMeasurementRulerNegZ,
}) {
  const pivotRef = useRef()

  mesh.geometry.computeBoundingBox()
  const size = new Vector3()
  mesh.geometry.boundingBox.getSize(size)
  const scale = Math.max(size.x, size.y, size.z)

  // heigh/width/depth measurement rulers for the mesh, place on the right-front side
  const [heightRulerPos, setHeightRulerPos] = useState([
    new Vector3(mesh.geometry.boundingBox.max.x, mesh.geometry.boundingBox.min.y, mesh.geometry.boundingBox.max.z), // start
    new Vector3(mesh.geometry.boundingBox.max.x, mesh.geometry.boundingBox.max.y, mesh.geometry.boundingBox.max.z), // end
  ])
  const [widthRulerPos, setWidthRulerPos] = useState([
    new Vector3(mesh.geometry.boundingBox.min.x, mesh.geometry.boundingBox.min.y, mesh.geometry.boundingBox.max.z), // start
    new Vector3(mesh.geometry.boundingBox.max.x, mesh.geometry.boundingBox.min.y, mesh.geometry.boundingBox.max.z), // end
  ])
  const [depthRulerPos, setDepthRulerPos] = useState([
    new Vector3(mesh.geometry.boundingBox.max.x, mesh.geometry.boundingBox.min.y, mesh.geometry.boundingBox.min.z), // start
    new Vector3(mesh.geometry.boundingBox.max.x, mesh.geometry.boundingBox.min.y, mesh.geometry.boundingBox.max.z), // end
  ])

  const handleClick = (e) => {
    e.stopPropagation() // to prevent selecting the last mesh intersected
    if (mouse.didMouseDrag()) return // to prevent selecting the mesh while dragging (mainly for pivot controls and orbit controls)
    if (e.object.isMesh && e.object.name === 'furniture') setActiveMeshId(e.object.id)
  }

  const raycasterRef = useRef(new Raycaster())
  raycasterRef.current.far = 16
  const intersects = useRef([])
  const rayOriginV3 = useRef(new Vector3(0, 0, 0))
  const meshCenter = useRef(new Vector3(0, 0, 0))
  const meshWorldPos = useRef(new Vector3(0, 0, 0))
  let i = 0

  const positiveXDir = useRef(new Vector3(1, 0, 0)) // right
  const negativeXDir = useRef(new Vector3(-1, 0, 0)) // left
  const positiveZDir = useRef(new Vector3(0, 0, 1)) // front
  const negativeZDir = useRef(new Vector3(0, 0, -1)) // back
  const negativeYDir = useRef(new Vector3(0, -1, 0)) // down

  const handlePivotDrag = () => {
    mesh.geometry.computeBoundingBox()
    mesh.geometry.boundingBox.getCenter(meshCenter.current)
    mesh.getWorldPosition(meshWorldPos.current)

    // ray from center positive x to positive x
    rayOriginV3.current.set(
      mesh.geometry.boundingBox.max.x + meshWorldPos.current.x,
      meshCenter.current.y + meshWorldPos.current.y,
      meshCenter.current.z + meshWorldPos.current.z
    )

    raycasterRef.current.set(rayOriginV3.current, positiveXDir.current)
    intersects.current = raycasterRef.current.intersectObjects(meshes)
    if (intersects.current.length > 0) {
      for (i = 0; i < intersects.current.length; i++) {
        if (intersects.current[i].object.name === 'furniture') {
          setMeasurementRulerPosXVisibility(true)
          setMeasurementRulerPosX([rayOriginV3.current.clone(), intersects.current[i].point.clone()])
          break
        } else {
          setMeasurementRulerPosXVisibility(false)
        }
      }
    } else {
      setMeasurementRulerPosXVisibility(false)
    }

    // ray from center negative x to negative x
    rayOriginV3.current.set(
      mesh.geometry.boundingBox.min.x + meshWorldPos.current.x,
      meshCenter.current.y + meshWorldPos.current.y,
      meshCenter.current.z + meshWorldPos.current.z
    )

    raycasterRef.current.set(rayOriginV3.current, negativeXDir.current)
    intersects.current = raycasterRef.current.intersectObjects(meshes)
    if (intersects.current.length > 0) {
      for (i = 0; i < intersects.current.length; i++) {
        if (intersects.current[i].object.name === 'furniture') {
          setMeasurementRulerNegXVisibility(true)
          setMeasurementRulerNegX([rayOriginV3.current.clone(), intersects.current[i].point.clone()])
          break
        } else {
          setMeasurementRulerNegXVisibility(false)
        }
      }
    } else {
      setMeasurementRulerNegXVisibility(false)
    }

    // ray from center positive z to positive z
    rayOriginV3.current.set(
      meshCenter.current.x + meshWorldPos.current.x,
      meshCenter.current.y + meshWorldPos.current.y,
      mesh.geometry.boundingBox.max.z + meshWorldPos.current.z
    )

    raycasterRef.current.set(rayOriginV3.current, positiveZDir.current)
    intersects.current = raycasterRef.current.intersectObjects(meshes)
    if (intersects.current.length > 0) {
      for (i = 0; i < intersects.current.length; i++) {
        if (intersects.current[i].object.name === 'furniture') {
          setMeasurementRulerPosZVisibility(true)
          setMeasurementRulerPosZ([rayOriginV3.current.clone(), intersects.current[i].point.clone()])
          break
        } else {
          setMeasurementRulerPosZVisibility(false)
        }
      }
    } else {
      setMeasurementRulerPosZVisibility(false)
    }

    // ray from center negative z to negative z
    rayOriginV3.current.set(
      meshCenter.current.x + meshWorldPos.current.x,
      meshCenter.current.y + meshWorldPos.current.y,
      mesh.geometry.boundingBox.min.z + meshWorldPos.current.z
    )

    raycasterRef.current.set(rayOriginV3.current, negativeZDir.current)
    intersects.current = raycasterRef.current.intersectObjects(meshes)
    if (intersects.current.length > 0) {
      for (i = 0; i < intersects.current.length; i++) {
        if (intersects.current[i].object.name === 'furniture') {
          setMeasurementRulerNegZVisibility(true)
          setMeasurementRulerNegZ([rayOriginV3.current.clone(), intersects.current[i].point.clone()])
          break
        } else {
          setMeasurementRulerNegZVisibility(false)
        }
      }
    } else {
      setMeasurementRulerNegZVisibility(false)
    }

    // ray from center negative y to negative y direction
    rayOriginV3.current.set(
      meshCenter.current.x + meshWorldPos.current.x,
      mesh.geometry.boundingBox.min.y + meshWorldPos.current.y - 0.02,
      meshCenter.current.z + meshWorldPos.current.z
    )

    raycasterRef.current.set(rayOriginV3.current, negativeYDir.current)
    intersects.current = raycasterRef.current.intersectObjects(meshes)
    if (intersects.current.length > 0) {
      for (i = 0; i < intersects.current.length; i++) {
        // TODO: this should be room
        if (intersects.current[i].object.name === 'furniture') {
          // prevent from going below
          console.log(pivotRef.current)
          pivotRef.current.position.y = intersects.current[i].point.y
          // mesh.position.y = intersects.current[i].point.y + 0.02
          break
        }
      }
    }
  }

  useFrame(() => {
    if (pivotRef.current) {
      // Clamp the Y position
      pivotRef.current.position.y = Math.max(pivotRef.current.position.y, 0.16000009846988628)
    }
  })

  const handlePivotDragStart = () => {
    orbitCtrlRef.current.enabled = false // to prevent orbiting while dragging mesh
  }

  const handlePivotDragEnd = () => {
    orbitCtrlRef.current.enabled = true
    setMeasurementRulerPosXVisibility(false)
    setMeasurementRulerNegXVisibility(false)
    setMeasurementRulerPosZVisibility(false)
    setMeasurementRulerNegZVisibility(false)
  }

  const handlePivotClick = (e) => {
    e.stopPropagation() // to prevent selecting the last mesh intersected
  }

  return (
    <>
      <PivotControls
        ref={pivotRef}
        name="pivot"
        lineWidth={1.5}
        scale={scale}
        visible={activeMeshId === mesh.id}
        disableAxes={activeMeshId !== mesh.id}
        disableRotations={activeMeshId !== mesh.id}
        disableScaling={activeMeshId !== mesh.id}
        disableSliders={activeMeshId !== mesh.id}
        onDragStart={handlePivotDragStart}
        onDragEnd={handlePivotDragEnd}
        onDrag={handlePivotDrag}
        onClick={handlePivotClick}
      >
        <primitive
          name="furniture"
          object={mesh}
          castShadow
          receiveShadow
          // onPointerEnter={(e) => {
          //   e.object.parent.traverse((child) => {
          //     if (child.name === 'outlines') child.visible = true
          //   })
          // }}
          // onPointerLeave={(e) => {
          //   e.object.parent.traverse((child) => {
          //     if (child.name === 'outlines') child.visible = false
          //   })
          // }}
          onClick={handleClick}
        >
          {measurementsActive && activeMeshId === mesh.id && (
            <DistanceMeasurement start={heightRulerPos[0]} end={heightRulerPos[1]} description={'Height'} />
          )}
          {measurementsActive && activeMeshId === mesh.id && (
            <DistanceMeasurement start={widthRulerPos[0]} end={widthRulerPos[1]} description={'Width'} />
          )}
          {measurementsActive && activeMeshId === mesh.id && (
            <DistanceMeasurement start={depthRulerPos[0]} end={depthRulerPos[1]} description={'Depth'} />
          )}

          {/* <Outlines name="outlines" thickness={0.02} color={0x000000} visible={false} /> */}
          {/* <box3Helper args={[mesh.geometry.boundingBox]} /> */}
        </primitive>
      </PivotControls>
    </>
  )
}
