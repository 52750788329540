import React from 'react'
import { Html } from '@react-three/drei'

const DistanceMeasurement = ({ start, end, description }) => {
  const lineRef = React.useRef()
  const textRef = React.useRef()

  React.useEffect(() => {
    if (lineRef.current) {
      lineRef.current.geometry.setFromPoints([start, end])
    }
  }, [start, end])

  const distance = start.distanceTo(end)

  return (
    <>
      <line ref={lineRef}>
        <bufferGeometry />
        <lineBasicMaterial color="red" />
      </line>
      <Html position={start.clone().lerp(end, 0.5)} center>
        <div ref={textRef} style={{ color: 'red' }}>
          {description}
          {distance.toFixed(2)}m
        </div>
      </Html>
    </>
  )
}

export default DistanceMeasurement
