import { MeshBasicMaterial, Vector3 } from 'three'
import { USDZLoader } from 'three/examples/jsm/loaders/USDZLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { useLoader } from '@react-three/fiber'

import { mergeGLTFMeshes } from './utils.js'

const basicMaterial = new MeshBasicMaterial()

export default class ModelsLoader {
  constructor() {
    this.models = []
    this.gltfLoader = new GLTFLoader()
    this.objLoader = new OBJLoader()
    this.mtlLoader = new MTLLoader()
  }
  async load(filePath, FileExt) {
    switch (FileExt) {
      case 'usdz':
        return this.loadUSDZ(filePath)
      case 'glb':
        return this.loadGLB(filePath)
      case 'gltf':
        return this.loadGLTF(filePath)
      case 'objmtl':
        return this.loadOBJMTL(objURL, mtlURL)
      case 'obj':
        return this.loadOBJ(filePath)
      default:
        console.warn(`format not supported: ${FileExt}`)
        break
    }
  }

  loadUSDZ(filePath) {
    return useLoader(USDZLoader, filePath)
  }

  loadGLB(filePath) {
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(filePath, (gltf) => {
        const mergedMeshes = mergeGLTFMeshes(gltf.scene.children[0])
        // const mergedMeshes = gltf.scene.children[0]
        // console.log(gltf.scene.children[0])

        const size = new Vector3()
        mergedMeshes.geometry.computeBoundingBox()
        mergedMeshes.geometry.boundingBox.getSize(size)

        //Average room is 3m high, so scale down by 0.1 if its bigger 3.3m hence the 3D modeler used cm instead of m,
        //if its bigger than 33.3m then scale down by 0.01 hence the 3D modeler used inches instead of m, and if its
        //bigger than 100m then scale down by 0.001 hence the 3D modeler used mm instead of m
        if (size.y > 100) mergedMeshes.scale.set(0.001, 0.001, 0.001)
        else if (size.y > 33.3) mergedMeshes.scale.set(0.01, 0.01, 0.01)
        else if (size.y > 3.3) mergedMeshes.scale.set(0.1, 0.1, 0.1)

        // offset model so that its bottom center is at (0,0,0)
        const center = new Vector3()
        mergedMeshes.geometry.boundingBox.getCenter(center)
        mergedMeshes.position.set(-center.x, size.y * 0.5 - center.y, -center.z)

        this.models.push(mergedMeshes)
        resolve(mergedMeshes.clone())
      })
    })

    // // using useLoader hook prevents us from using the onLoad callback
    // const gltf = useLoader(GLTFLoader, filePath)
    // return mergeGLTFMeshes(gltf.scene)
  }

  loadGLTF(filePath) {
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(filePath, (gltf) => {
        const mergedMeshes = mergeGLTFMeshes(gltf.scene)
        this.models.push(mergedMeshes)
        resolve(mergedMeshes.clone())
      })
    })

    // // using useLoader hook prevents us from using the onLoad callback
    // const gltf = useLoader(GLTFLoader, filePath)
    // return mergeGLTFMeshes(gltf.scene)
  }

  loadOBJMTL(objURL, mtlURL) {
    return new Promise((resolve, reject) => {
      this.mtlLoader.load(mtlURL, (mtl) => {
        mtl.preload()
        this.objLoader.setMaterials(mtl)
        this.objLoader.load(objURL, (objModel) => {
          // const mergedMeshes = mergeGLTFMeshes(objModel)
          this.models.push(mergedMeshes)
          resolve(objModel.clone())
        })
      })
    })
  }

  loadOBJ(filePath) {
    return new Promise((resolve, reject) => {
      this.objLoader.load(filePath, (objModel) => {
        // const mergedMeshes = mergeGLTFMeshes(objModel)
        objModel.traverse(function (child) {
          if (child.isMesh) {
            child.material = basicMaterial
          }
        })
        this.models.push(mergedMeshes)
        resolve(objModel.clone())
      })
    })

    // // using useLoader hook prevents us from using the onLoad callback
    // const obj = useLoader(OBJLoader, filePath)
    // return mergeGLTFMeshes(obj)
  }
}
