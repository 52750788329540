import * as THREE from 'three'

import { autoFitMeshToCameraFrustum } from './utils'

export default class MeshSnapShooter {
  #renderer = null
  #camera = null
  #scene = null

  constructor() {
    this.#renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    this.#renderer.setClearColor(0x000000, 0)
    this.#renderer.outputEncoding = THREE.sRGBEncoding
    this.#camera = new THREE.PerspectiveCamera(72, 1, 0.1, 100)
    this.#scene = new THREE.Scene()

    this.#renderer.setSize(512, 512)

    let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1.0)
    hemiLight.color.setHSL(0.6, 1, 0.6)
    hemiLight.groundColor.setHSL(0.095, 1, 0.75)
    hemiLight.position.set(0, 10, 0)
    this.#scene.add(hemiLight)
    let dirLight = new THREE.DirectionalLight(0xffffff, 2.0)
    dirLight.color.setHSL(0.1, 1, 0.95)
    dirLight.position.set(-1, 1.75, 1)
    dirLight.position.multiplyScalar(3)
    this.#scene.add(dirLight)
    this.#scene.add(new THREE.AmbientLight(0xf7f0d7, 1.0))
  }

  getPngOfMesh(mesh) {
    this.#scene.add(mesh)
    autoFitMeshToCameraFrustum(mesh, this.#camera)

    this.#renderer.render(this.#scene, this.#camera)

    const dataUrl = this.#renderer.domElement.toDataURL('image/png')
    this.#scene.remove(mesh)

    return dataUrl
  }

  clear() {
    this.#renderer = null
    this.#scene = null
    this.#camera = null
  }
}
