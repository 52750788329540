import React, { useState } from 'react'

import { useSceneRef } from '../../../useStore'

import ModelsLoader from '../../../core/ModelsLoader.js'
import Exporter from '../../../core/Exporter.js'
import FileInput from './FileInput.jsx'
import ModelIcon from './ModelIcon.jsx'

const modelsLoader = new ModelsLoader()
const exporter = new Exporter()

export default function SidePanel({ setMeshes, measurementsActive, setMeasurementsActive, peerToPeerOnClickHandle }) {
  const [modelIcons, setModelIcons] = useState([])
  const sceneRef = useSceneRef()

  return (
    <article className="w-full h-full py-4 px-2 z-20 flex flex-col flex-wrap gap-2 justify-between justify-items-center bg-black text-white">
      <header className="flex flex-col">
        <div className="flex flex-row justify-center">
          <img className="w-32 h-16" src="icons/white_logo_transparent_background.png" alt="inovise logo" />
        </div>
        <h1 className="text-center text-3xl font-bold">Planovise</h1>

        <FileInput setMeshes={setMeshes} setModelIcons={setModelIcons} modelsLoader={modelsLoader} />
      </header>
      <section className="grow flex flex-col">
        <div className="flex flex-row flex-wrap justify-start justify-items-start gap-2">
          {modelIcons &&
            modelIcons.map((modelIcon, modelIndex) => (
              <ModelIcon
                key={modelIndex}
                modelIndex={modelIndex}
                modelIcon={modelIcon}
                cachedModels={modelsLoader.models}
                setMeshes={setMeshes}
              />
            ))}
        </div>
      </section>
      <section className="flex flex-col gap-1">
        <button className="btn btn-block" onClick={() => peerToPeerOnClickHandle && peerToPeerOnClickHandle()}>
          Generate Video Conference Link
        </button>
        <button className="btn btn-block" onClick={() => setMeasurementsActive(!measurementsActive)}>
          {measurementsActive ? 'Hide' : 'Show'} Measurements
        </button>
        <div className="dropdown dropdown-top">
          <div tabIndex={0} role="button" className="btn btn-block">
            Export
          </div>
          <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-64 p-2 shadow">
            {/* <li>
              <button onClick={() => exporter.exportModel('usdz', sceneRef.current)}>USDZ</button>
            </li> */}
            <li>
              <button onClick={() => exporter.exportModel('glb', sceneRef.current)}>GLB</button>
            </li>
            <li>
              <button onClick={() => exporter.exportModel('gltf', sceneRef.current)}>GLTF</button>
            </li>
            <li>
              <button onClick={() => exporter.exportModel('obj', sceneRef.current)}>OBJ</button>
            </li>
          </ul>
        </div>
      </section>

      <footer>
        <div className="w-full flex justify-center">
          <a href="https://apps.apple.com/ca/app/scanovise/id6472953989" target="_blank">
            <img src="icons/Scanovise.jpeg" alt="Scanovise logo" width={128} height={128} />
          </a>
        </div>
      </footer>
    </article>
  )
}
