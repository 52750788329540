import { Mesh } from 'three'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js'

/**
 * moves the given camera to make the dimensions of the mesh fit in the frustum
 * @param {THREE.Mesh} mesh
 * @param {THREE.PerspectiveCamera} camera
 */
export const autoFitMeshToCameraFrustum = (mesh, camera) => {
  mesh.geometry.computeBoundingSphere()

  const scale = 3.5 // object size / display size
  const objectAngularSize = ((camera.fov * Math.PI) / 180) * scale
  const distanceToCamera = mesh.geometry.boundingSphere.radius / Math.tan(objectAngularSize / 2)
  const len = Math.sqrt(Math.pow(distanceToCamera, 2) + Math.pow(distanceToCamera, 2))

  camera.position.set(len, len * camera.aspect, len * 1.1)
  camera.lookAt(mesh.geometry.boundingSphere.center)
  camera.updateProjectionMatrix()
}

/**
 * traverses the THREE.Object3D to merge all geometries and materials
 * @param {THREE.Object3D} object3D with random meshes as children
 * @return {THREE.Mesh} THREE.Mesh with all the merged geometries and materials
 */
export const mergeGLTFMeshes = (object3D) => {
  const geometries = []
  const materials = []
  const materialIndices = []

  object3D.traverse((child) => {
    if (child.isMesh) {
      const geometry = child.geometry.clone()
      geometry.applyMatrix4(child.matrixWorld)
      geometries.push(geometry)
      materials.push(child.material)
      materialIndices.push(materials.length - 1)
    }
  })

  const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries, false)

  let offset = 0
  const groups = geometries.map((geometry, index) => {
    const count = geometry.index ? geometry.index.count : geometry.attributes.position.count
    const group = { start: offset, count: count, materialIndex: materialIndices[index] }
    offset += count
    return group
  })

  groups.forEach((group) => {
    mergedGeometry.addGroup(group.start, group.count, group.materialIndex)
  })

  return new Mesh(mergedGeometry, materials)
}
