import * as THREE from 'three'
import { USDZExporter } from 'three/addons/exporters/USDZExporter.js'
import { GLTFExporter } from 'three/addons/exporters/GLTFExporter.js'
import { OBJExporter } from 'three/addons/exporters/OBJExporter.js'

export default class Exporter {
  static FORMATS = {
    usdz: `usdz`,
    glb: `glb`,
    gltf: `gltf`,
    obj: `obj`,
  }

  constructor() {
    this.anchorLink = document.createElement('a')
    this.anchorLink.style.display = 'none'
    document.body.appendChild(this.anchorLink)
  }

  exportModel(format, scene) {
    if (!scene) {
      console.notice(`scene reference not initialized.`)
      return false
    }

    scene.traverse((child) => {
      if (child.type == 'GridHelper') {
        child.visible = false
      }
    })

    switch (format) {
      case Exporter.FORMATS.usdz:
        this.usdz(scene)
        break
      case Exporter.FORMATS.glb:
        this.glb(scene)
        break
      case Exporter.FORMATS.gltf:
        this.gltf(scene)
        break
      case Exporter.FORMATS.obj:
        this.obj(scene)
        break
      default:
        console.warn(`export format ${format} isn't supported.`)
    }

    scene.traverse((child) => {
      if (child.type == 'GridHelper') {
        child.visible = true
      }
    })
  }

  usdz(scene) {
    const usdzExporter = new USDZExporter()
    usdzExporter.parse(scene, (arraybuffer) => {
      this.download(
        new Blob([arraybuffer], { type: 'application/octet-stream' }),
        `inovise-roomplan-${Date.now() + Math.floor(Math.random() * 1000)}.usdz`
      )
    })
  }

  glb(scene) {
    const gltfExporter = new GLTFExporter()

    gltfExporter.parse(
      scene,
      (result) => {
        this.download(
          new Blob([result], { type: 'application/octet-stream' }),
          `inovise-roomplan-${Date.now() + Math.floor(Math.random() * 1000)}.glb`
        )
      },
      function (error) {
        console.error('An error happened during parsing', error)
      },
      { binary: true }
    )
  }

  gltf(scene) {
    const gltfExporter = new GLTFExporter()

    gltfExporter.parse(
      scene,
      (result) => {
        this.download(
          new Blob([JSON.stringify(result, null, 2)], { type: 'text/plain' }),
          `inovise-roomplan-${Date.now() + Math.floor(Math.random() * 1000)}.gltf`
        )
      },
      function (error) {
        console.error('An error happened during parsing', error)
      },
      { binary: false }
    )
  }

  obj(scene) {
    const objExporter = new OBJExporter()
    const result = objExporter.parse(scene)
    this.download(
      new Blob([result], { type: 'text/plain' }),
      `inovise-roomplan-${Date.now() + Math.floor(Math.random() * 1000)}.obj`
    )

    // // TODO: needs more research
    // let files = [
    //   { name: `${name}.obj`, data: result.obj },
    //   { name: `${name}.mtl`, data: result.mtl },
    // ]
    // downloadAsZip(files, `inovise-roomplan-${Date.now() + Math.floor(Math.random() * 1000)}.obj`)
  }

  download(blob, fileName) {
    window.URL = window.URL || window.webkitURL

    this.anchorLink.download = fileName
    this.anchorLink.href = window.URL.createObjectURL(blob)
    this.anchorLink.click()

    window.URL.revokeObjectURL(blob)
  }

  /**
   * @param files {array} an array of files to be included in the zip
   * file format {name: hello.txt, data: "hello world"}
   * @param zipName {string}
   */
  downloadAsZip(files, zipName) {
    var zip = new JSZip()
    for (let file of files) {
      zip.file(file.name, file.data)
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      // console.log(content);
      saveToDisk(content, `${zipName}.zip`)
    })
  }

  saveToDisk(blob, filename) {
    let downloadLink = document.createElement('a')

    downloadLink.download = filename
    downloadLink.innerHTML = 'My Hidden Link'

    window.URL = window.URL || window.webkitURL

    downloadLink.href = window.URL.createObjectURL(blob)
    downloadLink.target = '_blank'
    downloadLink.onclick = this.destroyClickedElement

    downloadLink.style.display = 'none'

    document.body.appendChild(downloadLink)

    downloadLink.click()
  }
}
