import ReactDOM from 'react-dom/client'
import { StrictMode, Suspense, useRef, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Loader } from '@react-three/drei'

import { SceneProvider } from './useStore'
import Experience from './Experience.jsx'
import FlipPhoneOnLandscape from './components/utils/FlipPhoneOnLandscape.jsx'
import SidePanel from './components/ui/side-panel'

import './event-listeners/prevent-ctrl-wheelscroll.js'
import PeerToPeerStream from './components/peer/index.jsx'

import './style.css'

const root = ReactDOM.createRoot(document.querySelector('#root'))
root.render(<Render />)

function Render() {
  const [meshes, setMeshes] = useState([])
  const [activeMeshId, setActiveMeshId] = useState(0)
  const [measurementsActive, setMeasurementsActive] = useState(false)

  const [peerToPeerOnClickHandle, setPeerToPeerOnClickHandle] = useState(null)

  const handlePointerMissed = () => {
    setActiveMeshId(0)
  }

  return (
    <StrictMode>
      <div className="w-full h-full">
        <aside className="w-2/12 h-full fixed left-0">
          <SidePanel
            setMeshes={setMeshes}
            measurementsActive={measurementsActive}
            setMeasurementsActive={setMeasurementsActive}
            peerToPeerOnClickHandle={peerToPeerOnClickHandle}
          />
        </aside>
        <main className="w-10/12 h-full fixed right-0">
          <Canvas
            camera={{
              fov: 75,
              near: 0.1,
              far: 100,
              position: [3, 2, 3],
            }}
            background={'#fff'}
            shadows
            onPointerMissed={handlePointerMissed}
          >
            <Suspense fallback={null}>
              <SceneProvider>
                <Experience
                  meshes={meshes}
                  setMeshes={setMeshes}
                  activeMeshId={activeMeshId}
                  setActiveMeshId={setActiveMeshId}
                  measurementsActive={measurementsActive}
                />
              </SceneProvider>
            </Suspense>
          </Canvas>
        </main>
        <PeerToPeerStream setPeerToPeerOnClickHandle={setPeerToPeerOnClickHandle} />
        <Loader />
        <FlipPhoneOnLandscape />
      </div>
    </StrictMode>
  )
}
