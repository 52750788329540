export default function ModelIcon({ modelIndex, modelIcon, cachedModels, setMeshes }) {
  return (
    <div
      onClick={() => {
        setMeshes((prevMeshes) => [...prevMeshes, cachedModels[modelIndex].clone()])
      }}
      className="w-16 h-16 rounded-md shadow-xl dark:shadow-gray-800"
    >
      <img
        className="w-full h-full opacity-80 transition duration-300 ease-in-out cursor-pointer hover:opacity-100 hover:scale-110 hover:shadow-lg"
        src={`data:image/png;${modelIcon}`}
      />
    </div>
  )
}
