import { useEffect } from 'react'

export default function FlipPhoneOnLandscape() {
  const isMobile = () => window.matchMedia('(max-width: 767px)').matches

  const supportsOrientationChange = 'onorientationchange' in window,
    orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize'

  useEffect(() => {
    window.addEventListener(
      orientationEvent,
      function () {
        const rotatePhoneSVG = document.querySelector('.rotate-phone')
        if (isMobile()) rotatePhoneSVG.style.display = 'block'
        else rotatePhoneSVG.style.display = 'none'
      },
      false
    )
  }, [])

  return (
    <svg
      className="rotate-phone"
      style={{ display: 'none' }}
      width="128px"
      height="128px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 40"
      x="0px"
      y="0px"
    >
      <g>
        <path d="M15.293,5.3936a3.0725,3.0725,0,0,0-4.2422,0L5.3936,11.05a3.0146,3.0146,0,0,0,0,4.2432L16.707,26.6064a3.0134,3.0134,0,0,0,4.2422,0L26.6064,20.95a3.0146,3.0146,0,0,0,0-4.2432ZM11.248,9.4385l-1.8095,1.81-.9425-.4712,2.2809-2.2806ZM25.1924,19.5352l-5.6582,5.6572a1.0224,1.0224,0,0,1-1.4131,0L6.8076,13.8789a1.0637,1.0637,0,0,1,.1978-1.6117l1.5385.77a1.9969,1.9969,0,0,0,2.3086-.375l1.81-1.81a1.9974,1.9974,0,0,0,.375-2.3086l-.77-1.5379a1.0628,1.0628,0,0,1,1.6114-.1984L25.1924,18.1211A1.0044,1.0044,0,0,1,25.1924,19.5352Z" />
        <path d="M22.3643,19.5352a1,1,0,0,1,0,1.414l-1.4151,1.4151a1,1,0,0,1-1.414-1.4141l1.415-1.415A1,1,0,0,1,22.3643,19.5352Z" />
        <path d="M29.9961,11.7236a1,1,0,0,1-1.9922.1758,5.8465,5.8465,0,0,0-5.4356-5.5065l.8087.9645a1,1,0,0,1-1.5313,1.2852L19.6328,6.0049a.999.999,0,0,1,.0361-1.3262l2.2129-2.3623a1,1,0,0,1,1.459,1.3672l-.6785.7243A7.9167,7.9167,0,0,1,29.9961,11.7236Z" />
        <path d="M12.3672,25.9951a.999.999,0,0,1-.0361,1.3262l-2.2129,2.3623a1,1,0,0,1-1.459-1.3672l.6748-.7205a7.9166,7.9166,0,0,1-7.33-7.32,1,1,0,0,1,1.9922-.1758,5.8489,5.8489,0,0,0,5.4374,5.5086l-.8105-.9666a1,1,0,0,1,1.5313-1.2852Z" />
      </g>
    </svg>
  )
}
