import { useRef, useEffect } from 'react'

import MeshSnapShooter from '../../../core/MeshSnapShooter.js'

const meshSnapShooter = new MeshSnapShooter()

export default function FileInput({ setMeshes, setModelIcons, modelsLoader }) {
  const fileInputRef = useRef(null)

  const addMeshToApp = (loadedModel) => {
    setModelIcons((prevModelIcons) => [...prevModelIcons, meshSnapShooter.getPngOfMesh(loadedModel)])
    setMeshes((prevModels) => [...prevModels, loadedModel])
  }

  // listen to user file input > send user file to loader > loader returns mesh > add modelIcon to UI > add mesh to scene
  useEffect(() => {
    const handleFileChange = async (event) => {
      const file1 = event.target.files[0]
      const file2 = event.target.files[1]

      if (file1) {
        const url = URL.createObjectURL(file1)
        const FileExt = file1.name.split('.').pop()

        try {
          // obj/mtl
          if (file2) {
            // loadedModel MUST BE A CONSTANT for this to work smoothly
            if (FileExt === 'obj') {
              const mtlURL = URL.createObjectURL(file2)
              const File2Ext = file2.name.split('.').pop()
              if (File2Ext === 'mtl') {
                const loadedModel = await modelsLoader.loadOBJMTL(url, mtlURL)
                if (!loadedModel) return console.error('Error loading model')
                addMeshToApp(loadedModel)
              }
              URL.revokeObjectURL(mtlURL)
            } else if (FileExt === 'mtl') {
              const objURL = URL.createObjectURL(file2)
              const File2Ext = file2.name.split('.').pop()
              if (File2Ext === 'obj') {
                const loadedModel = await modelsLoader.loadOBJMTL(objURL, url)
                if (!loadedModel) return console.error('Error loading model')
                addMeshToApp(loadedModel)
              }
              URL.revokeObjectURL(objURL)
            }
          } else {
            const loadedModel = await modelsLoader.load(url, FileExt)
            if (!loadedModel) return console.error('Error loading model')
            addMeshToApp(loadedModel)
          }
          URL.revokeObjectURL(url)
        } catch (error) {
          console.error('Error loading model:', error)
        }
      }
    }

    fileInputRef.current.addEventListener('change', handleFileChange)

    return () => {
      fileInputRef.current.removeEventListener('change', handleFileChange)
    }
  }, [fileInputRef])

  return (
    <>
      <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-white" htmlFor="file_input">
        Import Model
      </label>
      <input
        ref={fileInputRef}
        aria-describedby="file_input_help"
        id="file_input"
        className="file-input w-full max-w-xs"
        type="file"
        multiple
        accept=".gltf,.glb,.obj,.mtl,.usdz"
        style={{}}
      />
      <p className="mt-1 text-xs text-gray-500 dark:text-gray-400" id="file_input_help">
        GLB, GLTF, OBJ.
      </p>
    </>
  )
}
