export default class Mouse {
  constructor() {
    this.initialMousePosition = { x: 0, y: 0 }
    this.movedDistance = 0
    this.movementThreshold = 5

    document.addEventListener('mousedown', this.onMouseDown.bind(this))
    document.addEventListener('mouseup', this.onMouseUp.bind(this))
  }

  onMouseDown(event) {
    this.initialMousePosition = { x: event.clientX, y: event.clientY }
  }

  onMouseUp(event) {
    const currentMousePosition = { x: event.clientX, y: event.clientY }
    this.movedDistance = Math.sqrt(
      Math.pow(currentMousePosition.x - this.initialMousePosition.x, 2) +
        Math.pow(currentMousePosition.y - this.initialMousePosition.y, 2)
    )
  }

  didMouseDrag() {
    return this.movedDistance > this.movementThreshold
  }
}
